<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['syb']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['prjCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="状态">
              <a-select
                class="form-control"
                :options="typesMap.normal"
                v-decorator="['normal']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions"></div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="cameraIndexCode"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getSybList } from '@/service/getData/aie'
import { getTypeName } from '@/utils'

export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '区域',
        width: '8%',
        align: 'center',
        dataIndex: 'sybName',
      },
      {
        title: '项目',
        width: '14%',
        align: 'center',
        dataIndex: 'prjName',
      },
      {
        title: '时间',
        width: '7%',
        align: 'center',
        dataIndex: 'recordTime',
      },
      {
        title: 'aie智证平台过车总数',
        width: '10%',
        align: 'center',
        dataIndex: 'aieRecordCount',
      },
      {
        title: 'mpc海康平台过车总数',
        width: '10%',
        align: 'center',
        dataIndex: 'mpcRecordCount',
      },
      {
        title: 'dev海康设备过车总数',
        width: '10%',
        align: 'center',
        dataIndex: 'devRecordCount',
      },
      {
        title: '操作',
        width: '4%',
        align: 'center',
        customRender: (text, record) => {
          return (
            <div class="flex gap-4 justify-center">
              <a
                onClick={() => {
                  this.recountRow(record)
                }}
              >
                重算
              </a>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
      projectList: [],
      sybList: [],
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    typesMap() {
      return {
        normal: [
          {
            label: '正常',
            value: '1',
          },
          {
            label: '异常',
            value: '2',
          },
        ],
      }
    },
  },
  methods: {
    getTypeName,
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const params = this.getPageParams(_pageNo, _pageSize)
        if (params.remove === 1) {
          params.online = ''
        }
        const res = await this.$axios.post('/aie/web/truck/pass/record/count/page', {
          ...params,
        })
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async recountRow(row) {
      await this.$axios.post('/aie/web/truck/pass/record/count/recalculation/aieRecordCount', {
        id: row.id,
      })
      this.$message.success('操作成功')
      this.doQuery()
    },
  },
  async mounted() {
    this.loadProjectList()
    this.loadSybList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
